import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"

import CornerImg from "../images/12dprinter/corner_interface.png"
import GantryImg from "../images/12dprinter/gantry.png"
import NestingImg from "../images/12dprinter/nesting_gantries.png"
import ThumbImg from "../images/project_thumbnails/12dprinter.jpg"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: ThumbImg,
        alt: "Overall system view",
    },
    {
        img: CornerImg,
        alt: "Focus on interface at the corner",
    },
    {
        img: GantryImg,
        alt: "Subassembly of a single gantry",
    },
    {
        img: NestingImg,
        alt: "Nesting behavior of the gantries",
    },
]

const Printer12dPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>12d Printer</h1>
            <sub><em>What do you do when your Facebook internship goes remote and you have money to burn?</em></sub>
            {ProjectSeries({
                name:"12d Printer",
                prevname: "CSCE 462",
                prevlink: "/cs462",
                nextname: "Undergraduate Research",
                nextlink: "/urs",
            })}
            <p>I'm going to address the elephant in the room: its a ridiculous idea. A printer with multiple print heads that work <em>collaboratively</em>. In this case multiple means four. Imagine a pinter with four print heads working together. They could print a part in 1/4 of the time!. This is truly the future.</p>
            <p>Honestly, it started as something to do. I told some people and they said its not possible. Never one to back down from a vague challenge with no clear reward, I just went right to CAD. Slowly it took shape and, at least mechanically, was way more feasible than anyone expected. The mechanical design was inspired by the new-ish <a href="https://www.creality3dofficial.com/products/ender-5-pro-3d-printer">Ender 5</a> 3D printer. About a month and $800 later I had all the parts I needed. (I feel its necessary to say that I'm generally good with money; but this summer I was getting a California housing stipend and living in College Station; I had quite a bit of disposable income).</p>
            <p>The printer went together mechanically fairly easily. There really wasn't any glaring problems with how it was designed. I was later able to use the mechanical structure for my <a href="/cs462">CSCE 462</a> project: a webapp for controlling stepper motors. However the software was chaotic. I knew this would be a major software hurdle; there was only one other person in the world (publically) thinking about this with any seriousness and it was on <a href="https://www.reddit.com/r/3Dprinting/comments/iwdnin/developing_a_new_type_of_3d">Reddit</a>. Knowing that I was coming up on having to decide between doing a capstone and a research project, I got in touch with Dr. Shell (who did research on robots and planning) and ended up tackling the planning side of the problem as my <a href="/urs">Undergraduate Research Thesis</a>. Thats right, I used this project for nine credit hours and as my capstone. For $800 that was a bargain. <sub>Wait you still have to pay for the research course even if you bring your own project and materials?</sub></p>
            <p>Of corse its not that simple: the controls side was a major pain point and ultimately why no real finished prototype ever existed. It came down to that I needed to control 12 stepper motors concurrently with real(ish) time guarantees. No existing (open source) solution had that opportunity. Grbl can do three and a fourth mirroring one of the others. Marlin can do five, but thats only half of whats needed. In the end, I got busy with other projects and being a TA. I intend to go back some day but the death certificate may have been signed when I scavenged the stepper motors for my <a href="/scara">SCARA Arm</a> in early 2022.</p>
            <p>Looking back, I'm still really happy with the design; I hadn't done much design since the <a href="/marblesorter">Marble Sorter</a> some two+ years prior. The system mechanically worked and I managed to pack four print heads into an printer with the a slightly larger build volume and about the same exterior dimensions as the Ender 5 Pro. Maybe someday someone will write the control software that gets this whole style of printer off the ground.</p>
            <p>See also: <a href="/cs462">CSCE 462</a> project for as built pics.</p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default Printer12dPage